

export class Form {
	constructor(selector) {
		this.abortController = undefined
		this.form = null
		this.action = null

		this.form = document.querySelector(selector);
		if (!this.form) {
			throw new Error('Form not found');
		}
		this.action = this.form.getAttribute('action');
		this.form.addEventListener('submit', async (e) => {
			e.preventDefault();
			await this.submit();
		})
		this.inputs = this.form.querySelectorAll('input');
		this.inputs.forEach(input => {
			input.addEventListener('focus', (el) => {
				const parent = input.parentElement;
				const sibling = parent.nextElementSibling
				console.log(input, parent, sibling)
				if (sibling && sibling.tagName === 'DIV' && sibling.classList.contains('uk-form-danger')) {
					sibling.remove()
				}
			})
		})
	}

	async submit() {
		this.clearErrors();
		if (this.abortController) {
			this.abortController.abort()
			this.abortController = undefined
		}
		this.abortController = new AbortController();
		const response = await fetch(this.action, {
			method: "POST",
			body: new FormData(this.form),
			headers: {Accept: "application/json"},
		});

		response.ok ? this.showSuccess(await response.json()) : this.showErrors(await response.json());
	}
	showSuccess(data) {
		console.log(data)
		this.form.reset();
		this.form.style.display = 'none';
		this.form.nextElementSibling.classList.add('active')
	}

	clearErrors() {
		const errors = document.querySelectorAll('.uk-form-danger');
		errors.forEach(el => {el.remove()});
	}
	showErrors(data) {

		let errors = [];
		Object.entries(data.errors).forEach(([key, messages]) => {
			const field = this.form.querySelector(`[name="${key}"]`);
			if (field) {
				field.classList.add('error');
				const errorEl = document.createElement('div');
				errorEl.innerHTML = messages.join("<br>");
				errorEl.classList.add('uk-form-danger');
				field.parentElement.insertAdjacentElement('afterend', errorEl);
			} else {
				errors = errors.concat(messages);
			}
		});
		console.log(errors)
	}
}
